import React, { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { checkRole } from '@components/RoleCheck';
import GoBackLink from '@components/student/GoBackLink';
import { sortByTime } from '@helpers/date';
import { useNavigationState } from '@hooks/useNavigationState';
import { useToggle } from '@hooks/useToggle.hook';
import { skipToken } from '@reduxjs/toolkit/query';
import { GradeTeacher, Uuid } from '@store/api/apiTypes';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useDeleteGradesMutation, useGetGradesTeacherQuery, useRequestRetakeQuery, useSubmitGradesMutation } from '@store/api/gradesApi';
import { useGetTaskByIdQuery } from '@store/api/taskApi';
import { useGetUserByUuidQuery } from '@store/api/usersApi';
import { Role } from '@store/Roles';
import CompletedTask from '@views/student/StudentTask/CompletedTask';
import TaskInfo from '@views/teacher/GradesTask/TaskInfo';


import style from './TaskResult.module.scss';

export const TaskResult = () => {
    const { uuidCategory, uuidDiscipline, userId, materialId } = useParams();

    const [isOpen, { on, off }] = useToggle();
    const [deletedAttempt, setDeletedAttempt] = useState<string | null>(null);
    const [submitGrade] = useSubmitGradesMutation();

    const userQueryData = useGetUserByUuidQuery(userId ?? skipToken);
    const taskQueryData = useGetTaskByIdQuery(materialId ?? skipToken);
    const disciplineQueryData = useGetDisciplineByIdQuery(uuidDiscipline ?? skipToken);
    const gradesQueryData = useGetGradesTeacherQuery((userId && uuidDiscipline && materialId) ? {
        uuidsDiscipline: [uuidDiscipline],
        uuidsUser: [userId],
        uuidsMaterial: [materialId]
    } : skipToken);

    const retakeQueryData = useRequestRetakeQuery({ uuidMaterial: materialId as string, uuidUser: userId });

    const dataWithRetakeMemo = useMemo(() => {
        if (!gradesQueryData.data || !retakeQueryData.data) return [];
        const sortedRetakeData = sortByTime(gradesQueryData.data,  'updatedAt', 'desc');
        return sortedRetakeData.map(attempt => ({
            ...attempt,
            retakeAvailable: Boolean(retakeQueryData.data!.find(retake => retake.uuidAttempt === attempt.uuidAttempt))
        }));
    }, [gradesQueryData.data, retakeQueryData.data]);

    const [deleteGrades] = useDeleteGradesMutation();

    const backLink = `/materials/${uuidCategory}/course/${uuidDiscipline}/scores`;

    const { state } = useNavigationState();

    const onDeleteGrades = (attempt: Uuid): (() => void) | undefined => {
        if (checkRole(Role.Methodist)) {
            return () => {
                on();
                setDeletedAttempt(attempt);
            };
        }
    };

    const submitDelete = async () => {
        if (deletedAttempt) {
            await deleteGrades({
                uuidsAttempt: [deletedAttempt]
            }).unwrap();
            off();
        }
    };

    const handleRetake = async (grade: GradeTeacher) => {
        return await submitGrade({
            uuid: grade.uuid as string,
            uuidTeacher: grade.teacher?.uuid || '',
            grade: grade.grade,
            review: grade.teacherReview,
            retakeAvailable: true,
            type: 'retake'
        }).unwrap();
    };

    const getRetakeHandler = (grade: GradeTeacher & {retakeAvailable: boolean}, index: number) => {
        if (index === 0 && !grade.retakeAvailable && grade.checked && grade.teacher) {
            return handleRetake;
        }
    };

    if (!(
        userQueryData.isSuccess &&
        taskQueryData.isSuccess &&
        disciplineQueryData.isSuccess &&
        gradesQueryData.isSuccess
    )) return <NewLoader />;

    return (
        <Main>
            <div className={style.title_wrapper}>
                <GoBackLink to={backLink} state={state}>
                    Назад
                </GoBackLink>

                <h1 className={style.main_title}>Контрольная работа</h1>
            </div>
            <div className={style.content}>
                <TaskInfo
                    taskName={taskQueryData.data.name}
                    disciplineName={disciplineQueryData.data.name}
                    user={userQueryData.data}
                />
                {dataWithRetakeMemo.map((grade, index, array) => (
                    <CompletedTask
                        key={grade.uuid}
                        attempt={grade}
                        index={array.length - index}
                        methodistPreview
                        onDelete={onDeleteGrades(grade.uuidAttempt)}
                        handleRetake={getRetakeHandler(grade, index)}
                    />
                ))}
            </div>
            <DeleteItemModal
                itemType="taskAttempt"
                description="Данное действие нелья будет отменить."
                isOpen={isOpen}
                onClose={off}
                onDelete={submitDelete}
            />

        </Main>
    );
};
