import { useEffect, useRef, useState } from 'react';

import { Centrifuge, SubscribedContext, SubscribingContext, Subscription, UnsubscribedContext } from 'centrifuge';

import { updateTokens } from '@store/baseQuery';
import { selectAccessToken, selectDeviceUuid, selectRefreshToken } from '@store/features/authSlice';
import { selectJwtUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { MessageType, RecipientUser } from '@type/chat';

const wsHost = process.env.REACT_APP_CHAT_WS_ENDPONT ?? 'https://veip-chat-service-dev.ve.sheverev.com/';

type TSendFileData = {
    text?: string;
    recipients?: RecipientUser[];
    uuidsFile?: string[] | null;
    type: MessageType;
    uuid: string;
};

/**
 * Хук для взаимодействия с вебсокетами.
 *
 * @returns {Object} subscription объект подписки для взаимодействия с вебсокетом.
 */

export function useWebSocketConnect() {
    const dispatch = useAppDispatch();

    const [subscription, setSubscription] = useState<Subscription | null>(null);
    const [connection, setConnection] = useState<Centrifuge | null>(null);
    const [ready, setReady] = useState(false);

    const accessToken = useAppSelector(selectAccessToken);
    const refreshToken = useAppSelector(selectRefreshToken);
    const deviceId = useAppSelector(selectDeviceUuid);
    const user = useAppSelector(selectJwtUser);
	
    // Хранилище, где хранятся сообщения, пока не подключились к веб сокету
    const queueMessagesRef = useRef<TSendFileData[]>([]);

    /** Функция, которая срабатывает когда мы пытаемся подключиться к веб сокету */
    function onSubscribing(ctx: SubscribingContext) {
        console.log('🚀 ~ sub.on ~ subscribing', ctx);
    }

    /** Функция срабатывает при подключении к веб сокету */
    function onSubscribed(ctx: SubscribedContext) {
        console.log('🚀 ~ sub.on ~ subscribed !', ctx);
        setReady(true);
    }

    /** Функция срабатывает при потере соединения с веб сокетом или отписке */
    function onUnSubscribed(ctx: UnsubscribedContext) {
        console.log('🚀 ~ sub.on ~ unsubscribed !', ctx);
        setReady(false);
    }

    const sendMessage = ({ text, recipients, uuidsFile, type, uuid }: TSendFileData) => {
        if (ready && connection && user?.Uuid) {
            connection.publish(user.Uuid, {
                name: 'message_event',
                uuidSender: user.Uuid,
                uuid,
                id: deviceId,
                ...(recipients && { recipients }),
                payload: {
                    ...(text && { text }),
                    ...(uuidsFile && { uuidsFile }),
                    type
                }
            });
        } else {
            // Если связь с вебсокетом не установлена, то добавляем сообщение в очередь
            queueMessagesRef.current.push({ text, recipients, uuidsFile, type, uuid });
        }
    };

    const handleRefreshToken = () => {
        updateTokens({
            refresh: refreshToken,
            token: accessToken,
            duuid: deviceId,
            dispatch
        });
    };


    useEffect(() => {
        if (accessToken && user?.Uuid && !connection && !subscription) {
            console.log('🚀 ~ Создаем новую подписку');
            // Подключение вебсокет ендпоинта.
            const centrifuge = new Centrifuge(`${wsHost}/ws/connecting`, {
                token: accessToken,
                name: user.Uuid,
                minReconnectDelay: 10000
            });

            // Подписка на канал по уникальному Uuid пользователя
            const sub = centrifuge.newSubscription(user.Uuid, { token: accessToken });

            centrifuge.on('error', (ctx) => console.log(ctx));

            sub.on('subscribing', onSubscribing);

            sub.on('subscribed', onSubscribed);

            sub.on('unsubscribed', onUnSubscribed);

            sub.on('error', (ctx) => console.error('🚀 ~ sub.on ~ error:', ctx));

            centrifuge.on('connected', (ctx) => console.info('🚀 ~ centrifuge.on ~ connected:', ctx));
            centrifuge.on('connecting', (ctx) => console.info('🚀 ~ centrifuge.on ~ connecting:', ctx));

            centrifuge.on('disconnected', (ctx) => {
                console.error('🚀 ~ centrifuge.on ~ disconnected:', ctx);
                if (ctx.code === 3500) {
                    handleRefreshToken();
                }
            });

            // Триггер события подписки.
            sub.subscribe();

            // Триггер события коннекта.
            centrifuge.connect();
            setConnection(centrifuge);
            setSubscription(sub);
        }
    }, [accessToken, user?.Uuid, connection, subscription]);

    // При размонтирование компонента WebSocketProvider обнуляем все состояние и отписываем от прослушки сокета
    useEffect(() => {
        return () => {
            setReady(false);
            if (subscription && connection) {
                console.log('🚀 ~ return ~ Отписка от канала:');

                // Отписка от получения сообщений.
                subscription.off('subscribing', onSubscribing);
                subscription.off('subscribed', onSubscribed);
                subscription.off('unsubscribed', onUnSubscribed);

                // Отписка от канала.
                subscription.unsubscribe();

                subscription.removeAllListeners();
                connection.removeSubscription(subscription);

                // Дисконнект вебсокета.
                connection.disconnect();

                setConnection(null);
                setSubscription(null);
            }
        };
    }, [subscription, connection, user?.Uuid, accessToken]);

    // При восстановление соединения отправляем все сообщения в сокет, который хранились в очереди queueMessagesRef, когда соединение было оборвано
    useEffect(() => {
        if (ready && queueMessagesRef.current.length) {
            queueMessagesRef.current.forEach(message => {
                sendMessage(message);
            });
            queueMessagesRef.current = [];
        }
    }, [ready]);

    return { subscription, sendMessage, connection };
}
