import { useEffect, useState, DependencyList, useCallback } from 'react';

import { getIndexes } from '../helpers';
import { type PaginationProps } from '../Pagination';

export interface RowsData<T> {
    rows: T[];
    total: number;
}

interface UsePaginationProps<T> {
    cb?: (
        page: number,
        limit: number,
        start: number,
        end: number
    ) => Promise<RowsData<T>> | RowsData<T>;
    total?: number;
    defaultPage?: number;
    itemsPerPageOptions?: number[];
    defaultRowsPerPage?: number;
    initialRows?: T[];
    deps?: DependencyList;
}

export interface UsePaginationReturned<T> {
    loading: boolean;
    rows: T[],
    refresh: () => void;
    reset: () => void;
    handleSliceData: (data: T[]) => T[];
    page: number;
    limit: number;
    setTotal: (value: number) => void;
    start: number;
    end: number;
    paginationProps: PaginationProps;
}

export function usePagination<T>({
    cb,
    total,
    defaultPage = 1,
    itemsPerPageOptions = [25, 50, 100],
    defaultRowsPerPage = 25,
    initialRows = [],
    deps
}: UsePaginationProps<T>): UsePaginationReturned<T> {
    const [loading, setLoading] = useState(false);

    // данные для отображения на странице
    const [rows, setRows] = useState<T[]>([]);

    const [currentPage, setCurrentPage] = useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [totalRows, setTotalRows] = useState<number>(total || 0);
    const [isAll, setIsAll] = useState<boolean>(false);

    const getRows = async (page: number, rowsCount: number) => {
        if (!cb) return;
        setLoading(true);
        const { start, end } = getIndexes(page, totalRows, rowsCount);
        const rowsData = await cb(page, rowsCount, start, end);
        setTotalRows(rowsData.total);
        setLoading(false);
        setRows(rowsData.rows);
    };

    const onChangePage = async (page: number) => {
        setCurrentPage(page);
        getRows(page, rowsPerPage);
    };

    const changeRowsOptions = (rowsCount: number) => {
        const newRowsCountValue = rowsCount > 0 ? rowsCount : totalRows;

        setIsAll(false);
        if (rowsCount < 0) setIsAll(true);
        setRowsPerPage(newRowsCountValue);
        setCurrentPage(defaultPage);
        getRows(currentPage, newRowsCountValue);
    };

    const refresh = () => {
        getRows(currentPage, rowsPerPage);
    };

    const reset = () => {
        setTotalRows(total || 0);
        setCurrentPage(defaultPage);
        getRows(defaultPage, defaultRowsPerPage);
    };

    const setTotal = (value: number) => {
        setTotalRows(value);
        if(value < totalRows){
            const maxPage = Math.ceil(value / rowsPerPage);
            if(currentPage > maxPage){
                setCurrentPage(defaultPage);
            }
        }
    };

    // helper-function для обрезки всего массива на кусок, который нужен для отображения на странице
    const handleSliceData = useCallback((data: T[]) => {
        const { start, end } = getIndexes(currentPage, totalRows, rowsPerPage);
        return data.slice(start, end);
    }, [currentPage, totalRows, rowsPerPage]);

    // если данные необходимо получать с помощью cb - функции, вызывается при первом рендере
    useEffect(() => {
        if (!deps) {
            if (initialRows.length && currentPage === 1) {
                setRows(initialRows);
            } else {
                getRows(defaultPage, defaultRowsPerPage);
            }
        }
    }, []);

    // обнуляет состояние пагинации при изменении переданных зависимостей
    useEffect(() => {
        if (deps?.length) {
            reset();
        }
    }, deps);

    return {
        loading,
        rows,
        refresh,
        reset,
        handleSliceData,
        page: currentPage,
        limit: rowsPerPage,
        setTotal,
        ...getIndexes(currentPage, totalRows, rowsPerPage),
        paginationProps: {
            total: totalRows,
            currentPage,
            onChangePage,
            itemsPerPageOptions,
            rowsPerPage,
            minTotal: rowsPerPage,
            changeRowsOptions,
            noData: !totalRows,
            isAll
        }
    };
}
