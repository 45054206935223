import React, { FC, useState } from 'react';

import cx from 'classnames';
import { Link, useNavigate } from 'react-router-dom';

import { Column, Grid } from '@components/core/grid';
import IconUI from '@components/core/IconUI';
import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import { DataList } from '@components/DataList';
import { Pagination, usePagination } from '@components/Pagination';
import { checkDeadline, dateConvert } from '@helpers/date';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Paper, Popover } from '@mui/material';
import { UncheckedGrades  } from '@store/api/apiTypes';
import { useLazyGetExpiredGradesInTaskForTeacherQuery, useLazyGetExpiringGradesInTaskForTeacherQuery, useLazyGetUncheckedGradesInTaskForTeacherQuery } from '@store/api/gradesApi';
import { deadlineExpiresDaysGap, deadlineOverdueDaysGap, IGradesArray } from '@views/teacher/TeacherMain/TeacherMain';

import style from './GradesInTask.module.scss';


interface IGradesInTask {
    material: IGradesArray;
    filter: 'all-works' | 'deadline-expires' | 'overdue' | 'none';
}

const GradesInTask: FC<IGradesInTask> = ({ material, filter }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [currentDeadline, setCurrentDeadline] = useState<string>();

    const smUp = useBreakpoint('sm', 'up');

    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>, deadline: string) => {
        setAnchorEl(event.currentTarget);
        setCurrentDeadline(deadline);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [ getUncheckedGradesInTaskForTeacher, { isFetching } ] = useLazyGetUncheckedGradesInTaskForTeacherQuery();
    const [ getExpiredGradesInTaskForTeacher, { isFetching: expiredFetching  } ] = useLazyGetExpiredGradesInTaskForTeacherQuery();
    const [ getExpiringGradesInTaskForTeacher, { isFetching: expiringFetching } ] = useLazyGetExpiringGradesInTaskForTeacherQuery();

    const getGradesInTask = async ({ uuidTask, limit, offset } :{ uuidTask: string, limit: number, offset: number }) => {
        switch (filter) {
            case 'overdue':
                return await getExpiredGradesInTaskForTeacher({ uuidTask: uuidTask, limit: limit, offset: offset });
                
            case 'deadline-expires':
                return await getExpiringGradesInTaskForTeacher({ uuidTask: uuidTask, limit: limit, offset: offset });

            default:
                return await getUncheckedGradesInTaskForTeacher({ uuidTask: uuidTask, limit: limit, offset: offset });
        }
    };

    const { rows, paginationProps } = usePagination<UncheckedGrades>({
        itemsPerPageOptions: [ 10, 25, 50 ],
        defaultRowsPerPage: 10,
        initialRows: material.grades,
        total: material.total,
        cb: async (_page: number, limit: number, start: number) => {
            const { data } = await getGradesInTask({ uuidTask: material.uuidTask, limit: limit, offset: start });

            if (data) {
                return { rows: data.grades, total: material.total };
            } else {
                return { rows: [], total: 0 };
            }
        }
    });

    const open = Boolean(anchorEl);

    return (
        <>
            <div className={style.rows}>
                <div className={cx(style.list_item__header, style.list_item)}>
                    <Link to={material.uuidDiscipline} className={style.list_item__title_discipline}>
                        {material.nameDiscipline}
                    </Link>
                    {smUp && <span>/</span>}
                    <span className={style.list_item__task_name}>{material.nameTask}</span>
                </div>

                <DataList className={style.data_list}>
                    {
                        isFetching || expiredFetching || expiringFetching? <NewLoader className={style.loader} /> :
                            rows.map((grade) => {
                                return (
                                    <div key={grade.uuidUser} className={style.list_item}>
                                        <Grid className={style.student_grid}>
                                            <Column>
                                                <span className={style.list_item__student} onClick={() => navigate(`/user/${grade.uuidUser}`)}>
                                                    {grade.nameUser}
                                                </span>
                                            </Column>

                                            <Column>
                                                <div className={style.deadline_wrapper}>
                                                    <div
                                                        className={style.deadline}
                                                        onMouseEnter={(e) => handleClick(e, dateConvert(
                                                            grade.gradeCreatedAt.replace(/ \+0000 UTC/, '')
                                                        ).plus(deadlineOverdueDaysGap).toFormat('dd.LL.yyyy')
                                                        )}
                                                        onMouseLeave={handleClose}
                                                    >
                                                        <span className={style.deadline__date}>{dateConvert(
                                                            grade.gradeCreatedAt.replace(/ \+0000 UTC/, '')
                                                        ).toFormat('dd.LL.yyyy, HH:mm')}</span>
                                                        {
                                                            checkDeadline(grade.gradeCreatedAt, deadlineExpiresDaysGap) &&
                                                    <IconUI typeIcon="clock-red" />
                                                        }
                                                        {
                                                            checkDeadline(grade.gradeCreatedAt, deadlineOverdueDaysGap) &&
                                                    <span className={style.block_overdue}>Просрочено</span>
                                                        }
                                                    </div>

                                                    <LinkUI to={`/teaching/grades/${grade.uuidUser}/${material.uuidTask}`}
                                                        className={style.grade_link}>Оценить</LinkUI>
                                                </div>
                                            </Column>
                                        </Grid>
                                    </div>
                                );
                            })
                    }
                </DataList>
                <Pagination className={style.pagination} {...paginationProps} />
            </div>
            <Popover

                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                    pointerEvents: 'none'
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                disableRestoreFocus
                classes={{ paper: style['with-shadow'] }}
                style={{ top: 8, left: -110 }}
            >
                <Paper
                    className={style.paper}
                    variant="outlined"
                >
                    Срок проверки истекает {currentDeadline}
                </Paper>
            </Popover>
        </>
        
    );
};

export default GradesInTask;
