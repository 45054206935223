import React, { useCallback, useEffect, useRef, useState } from 'react';

import { PublicationContext } from 'centrifuge';
import { toast } from 'react-toastify';
import { DateTime } from 'ts-luxon';
import { v4 as uuidV4 } from 'uuid';

import { useChat } from '@components/Chat/context';
import { useWebSocketContext } from '@components/WebSocket/context';
import { getBase64 } from '@helpers/base64';
import { getDateDiff } from '@helpers/date';
import { fileTypes, IStorageFile } from '@store/api/apiTypes';
import { useUploadFileMutation, useLazyGetFileQuery } from '@store/api/storageApi';
import { chatApi, useCreateRoomMutation } from '@store/chatApi/chatApi';
import { addHistoryMessages, addNewMessage, initMessages, MAX_CHUNK_SIZE, replaceNewRoomUuid, selectMessages } from '@store/features/chatSlice';
import { selectApiUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { FileMessage, MessageType, RecipientUser, SocketMessage, TextMessage } from '@type/chat';

import { MessageAdapter } from '../helpers/messageApapter';

import { messagesContext } from './index';

const MAX_FILE_SIZE = 1024 * 1024 * 50;
const DAY_IN_MILISECONDS = 1000 * 60 * 60 * 24;

interface MessagesProviderProps {
    children: React.ReactNode;
}

export const MessagesProvider: React.FC<MessagesProviderProps> = ({ children }) => {
    const messageAdapterRef = useRef<MessageAdapter>();
    const [getFile] = useLazyGetFileQuery();

    if (!messageAdapterRef.current) {
        messageAdapterRef.current = new MessageAdapter();
    }

    const messageAdapter = messageAdapterRef.current;

    const { roomId, currentRoom, setRoomId, rooms, roomsWithNewMessages, setRoomsWithNewMessages } = useChat();
    const dispatch = useAppDispatch();

    // states
    const [canUpdate, setCanUpdate] = useState(false);
    const [chunk, setChunk] = useState(0);

    // selectors
    const user = useAppSelector(selectApiUser);
    const dataMessages = useAppSelector((state) => selectMessages(state, roomId));

    // api
    const [triggerMessages] = chatApi.endpoints.getRoomMessages.useLazyQuerySubscription();
    const [uploadFile] = useUploadFileMutation();
    const [createRoom] = useCreateRoomMutation();

    const alreadyPendingRef = useRef(false);

    const handleResponseMessages = async (chunkValue = 0) => {
        if (roomId === 'new') {
            return;
        }
        if (alreadyPendingRef.current) return;
        if (roomId) {
            alreadyPendingRef.current = true;
            const messagesData = await triggerMessages({
                uuid: roomId,
                offset: chunkValue * MAX_CHUNK_SIZE,
                limit: MAX_CHUNK_SIZE
            }).unwrap();

            if (chunkValue === 0) {
                dispatch(initMessages({ roomId, messages: messagesData.messages }));
            } else {
                dispatch(addHistoryMessages({ roomId, messages: messagesData.messages }));
            }

            if (messagesData.messages?.length === MAX_CHUNK_SIZE) {
                setCanUpdate(true);
            } else {
                setCanUpdate(false);
            }
            alreadyPendingRef.current = false;
        }
    };

    const getStartChunk = () => {
        setChunk(0);
        handleResponseMessages();
    };

    const onGetMessage = useCallback(async (ctx: PublicationContext) => {
        const socketMessage = ctx.data as SocketMessage;
        if (getDateDiff(socketMessage.payload.createdAt ?? '', 'iso') > DAY_IN_MILISECONDS) {
            return;
        }
        const uuidRoom = socketMessage.recipients[0].uuidRoom ?? roomId!;
        const isRoomAvailable = !!rooms?.find(room => room.uuid === uuidRoom);
        if (!isRoomAvailable) {
            dispatch(chatApi.util.invalidateTags(['Rooms']));
            if (roomId === 'new') {
                setRoomId(uuidRoom);
            }
        }
        if (uuidRoom !== roomId && !roomsWithNewMessages?.includes(uuidRoom)) {
            setRoomsWithNewMessages([...roomsWithNewMessages, uuidRoom]);
        }
        let files: IStorageFile[] = [];
        if (socketMessage.payload.type === MessageType.File) {
            const fileUuid = socketMessage.payload.uuidsFile[0];
            await getFile(fileUuid).unwrap().then(data => {
                files = data.files;
            });
        }
        const message: (TextMessage | FileMessage) & {key: string | null, loading: boolean} = {
            id: Date.now().toString(),
            uuidSender: socketMessage.uuidSender,
            type: socketMessage.payload.type,
            text: socketMessage.payload.text ?? '',
            key: null,
            createdAt: DateTime.fromISO(socketMessage.payload.createdAt, { zone: 'utc' }).toFormat('yyyy-LL-dd HH:mm:ss'),
            uuidRoom,
            loading: false,
            uuid: socketMessage.uuid,
            files
        };
        messageAdapter?.receiveMessage(
            message,
            (data) => dispatch(addNewMessage(data))
        );
    }, [roomsWithNewMessages, roomId]);

    const { sendMessage, subscription } = useWebSocketContext();

    useEffect(() => {
        if (roomId) {
            getStartChunk();
        }
    }, [roomId]);

    useEffect(() => {
        const currentChunk = Math.ceil(dataMessages.length / MAX_CHUNK_SIZE);
        if (!canUpdate) {
            if (!((dataMessages.length / currentChunk) % MAX_CHUNK_SIZE)) {
                setCanUpdate(true);
            }
        }
        setChunk(currentChunk - 1);
    }, [dataMessages.length]);

    const submitTextMessage = async (messageText: string) => {
        let recipients: RecipientUser[] = currentRoom?.users.map((userRoom) => ({ uuid: userRoom.uuid, uuidRoom: currentRoom?.uuid })) ?? [];
        let currentRoomId = roomId;
        if (roomId === 'new' && user) {
            const toUserUuid = currentRoom?.users.find(recipient => recipient.uuid !== user.uuid)?.uuid ?? '';
            const newRoom = await createRoom({ fromUser: user.uuid, toUser: toUserUuid }).unwrap();
            recipients = recipients.map(recipient => ({ ...recipient, uuidRoom: newRoom.uuid }));
            dispatch(replaceNewRoomUuid(newRoom.uuid));
            setRoomId(newRoom.uuid);
            currentRoomId = newRoom.uuid;
        }

        const message: TextMessage & {key: string | null, loading: boolean} = {
            id: Date.now().toString(),
            uuidSender: user!.uuid,
            type: MessageType.Text,
            text: messageText,
            key: null,
            createdAt: DateTime.utc().toFormat('yyyy-LL-dd HH:mm:ss'),
            uuidRoom: currentRoomId!,
            loading: true,
            uuid: uuidV4()
        };
        messageAdapter?.pushMessage(
            message,
            (messageData) => dispatch(addNewMessage(messageData)),
            (messageData) => sendMessage({ type: messageData.type, text: messageData.text, recipients, uuid: messageData.uuid })
        );
    };


    const submitFileMessage = async (fileData: IStorageFile, file: File) => {
        if (file.size > MAX_FILE_SIZE) {
            toast.error('Максимальный размер файла 50 Мб');
            return;
        }

        let recipients: RecipientUser[] = currentRoom?.users.map((userRoom) => ({ uuid: userRoom.uuid, uuidRoom: currentRoom?.uuid })) ?? [];
        let currentRoomId = roomId;
        if (roomId === 'new' && user) {
            const toUserUuid = currentRoom?.users.find(recipient => recipient.uuid !== user.uuid)?.uuid ?? '';
            const newRoom = await createRoom({ fromUser: user.uuid, toUser: toUserUuid }).unwrap();
            recipients = recipients.map(recipient => ({ ...recipient, uuidRoom: newRoom.uuid }));
            dispatch(replaceNewRoomUuid(newRoom.uuid));
            setRoomId(newRoom.uuid);
            currentRoomId = newRoom.uuid;
        }

        const message: Omit<FileMessage, 'files'> & {key: string | null, loading: boolean} = {
            id: Date.now().toString(),
            uuidSender: user!.uuid,
            type: MessageType.File,
            key: null,
            createdAt: DateTime.utc().toFormat('yyyy-LL-dd HH:mm:ss'),
            uuidRoom: currentRoomId!,
            loading: true,
            uuid: uuidV4()
        };

        dispatch(addNewMessage({ ...message, files: [fileData] }));

        try {
            const b64 = await getBase64(file);
            const uploadedFile = await uploadFile({
                file: b64 as string,
                meta: {
                    filename: file.name,
                    type: fileTypes.Public
                },
                toastDisabled: true
            }).unwrap();

            dispatch(addNewMessage({ ...message, files: [fileData], storageLoaded: true }));
            sendMessage({ ...message, uuidsFile: [uploadedFile.uuid], recipients });
        } catch (error) {
            dispatch(addNewMessage({ ...message, files: [fileData], storageError: true }));
        }
    };

    const checkAndRequestChunk = () => {
        if (canUpdate) {
            return new Promise((resolve, reject) => {
                // need timer for smooth scroll
                setTimeout(async () => {
                    try {
                        await handleResponseMessages(chunk + 1);
                        resolve(void 0);
                    } catch (error) {
                        reject(error);
                    }
                }, 300);
            });
        }
    };

    useEffect(() => {
        if (subscription) {
            subscription.on('publication', onGetMessage);
        }

        return () => {
            if (subscription) {
                subscription.off('publication', onGetMessage);
            }
        };
    }, [subscription, onGetMessage]);

    return (
        <messagesContext.Provider value={{
            checkAndRequestChunk,
            canUpdate,
            submitTextMessage,
            submitFileMessage
        }}>
            {children}
        </messagesContext.Provider>
    );
};
