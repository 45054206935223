import React, { FC } from 'react';

import Button from '@components/core/inputs/Button';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import { dateConvert } from '@helpers/date';
import { User } from '@type/users';

import style from './GradeReview.module.scss';

interface IGradeReview {
    grade: number;
    teacherReview: string;
    teacher?: User,
    updatedAt: string;
    handleRetake?: () => void;
}

const GradeReview: FC<IGradeReview> = ({ grade, teacherReview, updatedAt, handleRetake, teacher }) => {

    return (
        <div className={style.wrapper}>
            <h3 className={style.title}>Оценка</h3>
            <div className={style.content}>
                <div className={style.scores_wrapper}>
                    <span className={style.scores}>{grade} баллов</span>
                    <span className={style.date}>
                        Работа проверена:
                        {dateConvert(updatedAt.replace(/ \+0000 UTC/, '')).toFormat(' dd.LL.yyyy, в HH:mm')}
                    </span>
                    {
                        teacher && <span className={style.teacher}>
                            Проверил: {teacher.lastName} {teacher.firstName} {teacher.patronymic}
                        </span>
                    }
                </div>
                <div className={style.review_container}>
                    <h3 className={style.feedback_title}>Отзыв к работе</h3>
                    <CSVPreview content={teacherReview} />
                </div>
            </div>
            {handleRetake && (
                <Button className={style['retake-button']} onClick={handleRetake}>
                    Дать новую попытку
                </Button>
            )}
        </div>
    );
};

export default GradeReview;
